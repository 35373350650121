.Collapsible {
    background-color: #d0cece;
    width: 100%;
    color: white;

    span {
        display: block;
        font-weight: 400;
        border: 1px solid white;
        padding: 3px 12px;
        cursor: pointer;
        transition: opacity 0.3s;
        &.success {
            background-color: #26a69a;
        }
        &.pending {
            background-color: #757575;
        }
        &.error {
            background-color: #f68c96;
        }
        &.external {
            background-color: #7dc2ff;
        }
    }

    span[class*='__trigger']:hover {
        opacity: 0.7;
    }

    div[class*='__contentOuter'] {
        background-color: #fafafa;
        color: #444;
    }

    div[class*='__contentInner'] {
        padding: 6px 12px;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
}
